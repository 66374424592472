import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function BlockHomeTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-4">
        <div className="max-w-6xl md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[50%]">
            <div
              className="w-full md:h-[600px] h-[350px] bg-cover bg-center"
              style={{ backgroundImage: `url("${rpdata?.stock?.[5]}")` }}
            ></div>
          </div>
          <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[0].slogan}</h2>
            <p>{rpdata?.dbAbout?.[1].text}</p>
            {/* <ul className="list-inner-section pl-3 text-start">
              {rpdata?.dbServices?.slice(0,6).map((item, index) => {
                return <li key={index} className="py-2"><i className="fa fa-arrow-right mr-2"></i>{item.name}</li>;
              })}
            </ul> */}
            <ButtonContent btnStyle='three'   />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockHomeTwo;
