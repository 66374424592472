import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function SocialMedia() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="wrapper text-center flex justify-center item-center">
        <ul className="social-icons icon-circle list-unstyled list-inline mt-6 text-center">
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} target="_blank" rel='noopener noreferrer'>
                  <i
                    className={`fab fa-${item.icon} wp-icon`}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default SocialMedia;
